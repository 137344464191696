import CryptoJS from 'crypto-js';
import config from '@/config';

const ua = navigator.userAgent
export const isAndroid = ua.indexOf('Android') !== -1 || ua.indexOf('Linux') !== -1
export const isIpad = !!((/macintosh|mac os x/i.test(navigator.userAgent) && window.screen.height > window.screen.width && !navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) || navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/))
export const isIOS = /iphone|ipad|ipod/i.test(ua) || !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isIpad
export const isHuawei = /Huawei|HarmonyOS/i.test(ua) || ua.includes("HuaweiBrowser")
export const isPc = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|SymbianOS|Windows Phone/i.test(ua) === false && !isIOS
// 安卓 chrome 浏览器，包含 原生chrome浏览器、三星自带浏览器、360浏览器以及早期国内厂商自带浏览器
export const isOriginalChrome = /chrome\/[\d.]+ mobile safari\/[\d.]+/i.test(ua) && isAndroid && ua.indexOf('Version') < 0;

// 获取用户信息
export function getAuthInfo() {
    let auth
    try {
        const authStr = localStorage.getItem('auth') || ''
        auth = JSON.parse(authStr)
    } catch (e) {
        return
    }
    return auth
}

// 设置用户信息
export function setAuthInfo(auth: UserBasicInfo) {
    localStorage.setItem('auth', JSON.stringify(auth))
}

// 清除用户信息
export function clearAuthInfo() {
    localStorage.removeItem('auth')
}

//des + base64加密
export function DESAndBase64Encrypt(dataToEncrypt: string) {
    const keyHex = CryptoJS.enc.Utf8.parse(config.encryptKey);
    const encrypted = CryptoJS.DES.encrypt(dataToEncrypt, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}