import React, { memo, useEffect, useState } from "react"
import { exitWeb } from "@/utils/bridge"
import { useTrans } from "@/hooks/useTranslation"
import './index.scss'

function Tips() {
  const { t } = useTrans()
  const [ deepLink, setDeepLink ] = useState('')

  useEffect(() => {
    const isInApp = process.env.REACT_APP_NAME && window.navigator.userAgent.indexOf(process.env.REACT_APP_NAME) > -1
    if (isInApp) {
      setTimeout(exitWeb, 2000)
    } else {
      setDeepLink('itslays://topup')
    }
  }, [exitWeb])

  return <div className="pg-tips">
    {t('Tips.PaySuccess')}
    <br />
    {!deepLink && <a onClick={exitWeb}>{t('Tips.Exit')}</a>}
    {deepLink && <a href={deepLink}>{t('Tips.GoToApp')}</a>}
  </div>
}

export default memo(Tips)