import React, { memo, useEffect, useMemo, useRef } from 'react'
import ImgGiftGray from '../images/gift-gray.png'
import ImgGift from '../images/gift.png'
import clsx from 'clsx'
import './AwardProgress.scss'

function GiftBox({ activate }: { activate?: boolean }) {
    return <>
        <div className='cmp-gift-box'>
            <img src={activate ? ImgGift : ImgGiftGray} alt="" />
        </div>
    </>
}

interface AwardProgressType {
    current: number
}
function AwardProgress(props: AwardProgressType) {
    const refConstantNum = useRef(20) //间隔数
    const refProgress = useRef<HTMLDivElement | null>(null)
    const refCurrent = useRef<HTMLDivElement | null>(null)
    const interval = useMemo(() => {
        const multiple = props.current / refConstantNum.current
        const begin = Math.floor(multiple) <= 1 ? 0 : Number.isInteger(Math.floor(multiple) / 2) ? Math.floor(multiple) * refConstantNum.current : (Math.floor(multiple) - 1) * refConstantNum.current
        const middle = begin + refConstantNum.current
        const end = middle + refConstantNum.current
        return {
            begin,
            middle,
            end
        }
    }, [props.current])
    const progress = useMemo(() => {
        return `${(props.current - interval.begin) / (refConstantNum.current * 2) * 100}%`
    }, [props.current, interval])
    useEffect(() => {
        if (refProgress.current) {
            refProgress.current.style.setProperty('--progress', progress)
        }
        if (refCurrent.current) {
            refCurrent.current.style.setProperty('--progress', progress)
        }
    }, [progress])
    return (
        <div className='cmp-award-progress'>
            <div className={clsx('cmp-award-progress-current', { 'current-transform': parseInt(progress) >= 90, 'current-transform2': parseInt(progress) > 92 })}>
                <div className='cmp-award-progress-current__content' ref={refCurrent}>{props.current}</div>
            </div>
            <div className='cmp-award-progress-progress'>
                <div className='unactivate'></div>
                <div className='activate' ref={refProgress}></div>
                <div className='cmp-award-progress-gift'>
                    <GiftBox activate={parseInt(progress) >= 50} />
                    <GiftBox />
                </div>
            </div>
            <div className='cmp-award-progress-nums'>
                <span className='activate'>{interval.begin}</span>
                <span className={clsx({ activate: parseInt(progress) >= 50 })}>{interval.middle}</span>
                <span>{interval.end}</span>
            </div>
        </div>
    )
}

export default memo(AwardProgress)