import clsx from 'clsx'
import { t } from 'i18next'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  linkPay,
  getLinkInfo
} from '@/services/thirdRecharge'
import { isIOS } from '@/utils/tool'
import { toast } from '@/utils/bridge'
import langIcon from '@/pages/ThirdRecharge/images/lang-icon.png'
import Loading from '@/components/Loading'
import HelmetProviders from '@/components/HelmetProviders'
import moment from 'moment';
import Highlight from '@/components/Highlight';
import { formatMoney } from '@/utils/format';
import SpecifyRechargeMethods from '@/pages/ThirdRecharge/components/SpecifyRechargeMethods'
import { useSearchParams } from 'react-router-dom'
import './SpecifyRecharge.scss'

const langText: Record<string, string> = {
  en: 'English',
  ar: 'العربية'
}

const initControlVal = {
  countryId: undefined,
  countryName: '',
  selectProduct: '',
  isSafari: isIOS
}

export default function SpecifyRecharge() {
  const [params] = useSearchParams()
  const refChargeRecordId = useRef(params.get('chargeRecordId'))
  refChargeRecordId.current = params.get('chargeRecordId')

  const [loading, setLoading] = useState<boolean>(true)
  const [openLang, setOpenLang] = useState<boolean>(false)

  const refLoading = useRef(loading)
  refLoading.current = loading

  const [controlVal, setControlVal] = useState<
    {
      selectProduct?: string
      countryName?: string
      isSafari: boolean
    } & ThirdRecharge.Country
  >(JSON.parse(JSON.stringify(initControlVal)))

  const refControlVal = useRef(controlVal)
  refControlVal.current = controlVal

  const [linkInfo, setLinkInfo] = useState<ThirdRecharge.LinkInfo>()
  const refLinkInfo = useRef(linkInfo)
  refLinkInfo.current = linkInfo

  /* 倒计时 */
  const refIntervalId = useRef<NodeJS.Timer>()
  const [remainingSeconds, setRemainingSeconds] = useState<number>();
  const refRemainingSeconds = useRef(remainingSeconds)
  refRemainingSeconds.current = remainingSeconds
  const countdown = useMemo(() => {
    return remainingSeconds ? moment.utc(remainingSeconds * 1000).format('HH:mm:ss') : '0'
  }, [remainingSeconds]);

  // 获取定位国家
  // const fetchLocationCountry = useCallback(async () => {
  //   const res = await getLocationCountry()

  //   const { code, message, data } = res as Response<ThirdRecharge.Country>
  //   if (code !== 200) {
  //     toast(message)
  //     return
  //   }
  //   setControlVal({ ...refControlVal.current, ...data })
  // }, [])

  // 获取链接信息
  const fetchLinkInfo = useCallback(async (chargeRecordId: string) => {
    setLoading(true)
    const { code, data, message } = await getLinkInfo(chargeRecordId) as Response<ThirdRecharge.LinkInfo>
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return
    setLinkInfo(data)
    setRemainingSeconds(data.remainingTime)
    // 倒计时
    refIntervalId.current = setInterval(() => {
      setRemainingSeconds((prevSeconds) => Math.max(0, (prevSeconds ? prevSeconds : 0) - 1));
    }, 1000);
  }, [])

  const updateControlVal = useCallback(async (data: object) => {
    setControlVal({ ...refControlVal?.current, ...data })
  }, [])

  const handleConfirm = useCallback(async () => {
    if (refRemainingSeconds.current === 0) {
      toast(t('ThirdRecharge.OrderExpired', {APP: process.env.REACT_APP_DISPLAY_NAME}))
      return
    }
    if (refLinkInfo.current?.redirectUrl) {
      window.location.href = refLinkInfo.current?.redirectUrl
      return
    }
    if (refLoading.current || !refControlVal.current.selectProduct || !refChargeRecordId.current) return

    const paramVal = refControlVal.current.selectProduct
      ?.split('-')
      .filter((v) => v !== '-')

    setLoading(true)
    const res = await linkPay({
      payType: String(paramVal?.[0]),
      chargeLinkRecordId: refChargeRecordId.current,
      isSafari: refControlVal.current.isSafari
    })
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<{ redirectUrl: string }>
    if (code !== 200) {
      toast(message)
      return
    }
    toast(t('Common.Loading'))

    window.location.href = data.redirectUrl
  }, [])

  const handleSelectLang = (lang: string) => {
    const curLang = localStorage.getItem('lang')
    if (curLang === lang) {
      setOpenLang(false)
      return
    }
    localStorage.setItem('lang', lang)
    setOpenLang(false)
    setTimeout(() => {
      const url = new URL(window.location.href)
      url.searchParams.set('lang', lang)
      // 将浏览器URL地址更新为修改后的URL,否则当url存在lang参数时会有问题
      window.history.replaceState(null, '', url)
      window.location.reload()
    }, 100)
  }

  useEffect(() => {
    if (remainingSeconds !== undefined && remainingSeconds <= 0) {
      clearInterval(refIntervalId.current)
    }
  }, [remainingSeconds])

  useEffect(() => {
    return () => clearInterval(refIntervalId.current)
  }, [])
  // 初始参数请求
  useEffect(() => {
    const chargeRecordId = params.get('chargeRecordId')
    if (chargeRecordId) {
      fetchLinkInfo(chargeRecordId)
    }
  }, [params, fetchLinkInfo])
  return (
    <HelmetProviders title={t('Common.TopUp')}>
      <div className="pg-specify-recharge">
        <div className="sticky-header">
          <div className="cmp-pane first">
            <div className="cmp-pane__title">
              {t('Guild.Tips.PleaseCheckYourAccountInformation')}
            </div>

            <div
              className={clsx('pg-specify-recharge-lang', {
                display: openLang
              })}>
              <div
                className="pg-specify-recharge-lang-current"
                onClick={() => setOpenLang(!openLang)}>
                <span className="pg-specify-recharge-lang-current-text">
                  {t('Common.lang')}
                </span>
                <img
                  className="pg-specify-recharge-lang-current-icon"
                  alt="img"
                  src={langIcon}
                />
              </div>
              <div
                onClick={() => handleSelectLang('en')}
                className="pg-specify-recharge-lang-options">
                {langText['en']}
              </div>
              <div
                onClick={() => handleSelectLang('ar')}
                className="pg-specify-recharge-lang-options">
                {langText['ar']}
              </div>
            </div>
          </div>

          <div className="pg-specify-recharge__input">
            {linkInfo && (
              <div className="pg-specify-recharge__user">
                <img
                  className="pg-specify-recharge__user-avatar"
                  alt="img"
                  src={linkInfo.avatar}
                />
                <div className="pg-specify-recharge__user-info">
                  <span className="pg-specify-recharge__user-name">
                    {linkInfo.nick}
                  </span>
                  <span className="pg-specify-recharge__user-id">
                    ID:{linkInfo.erbanNo}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="max-height-style">
          {/* <div className="pg-specify-recharge-country">
            <img
              className="pg-specify-recharge-country-icon"
              alt="img"
              src={ImageCountry}
            />
            <span >
              {controlVal?.countryName || ''}
            </span>
          </div> */}
          <SpecifyRechargeMethods
            selectProduct={String(controlVal.selectProduct)}
            appointPro={{
              goldNum: Number(linkInfo?.coins) || 0,
              money: linkInfo?.payAmount.toString() || '0',
              cashbackGoldNum: Number(linkInfo?.cashbackGoldNum) || 0,
              currencyTypeName: linkInfo?.currencyCode || ''
            }}
            appointCountryId={linkInfo?.countryId}
            updateControlVal={updateControlVal}
          />
        </div>
        {remainingSeconds !== undefined &&
          <div className='pg-specify-recharge__countdown'>
            {remainingSeconds <= 0 ? <>{t('ThirdRecharge.OrderExpired', {APP: process.env.REACT_APP_DISPLAY_NAME})}</> :
              <Highlight keys={[countdown]}>
                {t('ThirdRecharge.LimitedTimeForAppId', { time: countdown, appID: linkInfo?.erbanNo })}
              </Highlight>
            }
          </div>
        }
        {linkInfo && controlVal.selectProduct && (
          <div className='pg-specify-recharge__bottom-content'>
            <div className='pg-specify-recharge__bottom-total'>
              <div className="left">
                <span>$1 = </span>
                <span>{linkInfo.actualUnitPrice + t('ThirdRecharge.Coins')}</span>
              </div>
              <div className="right">
                <span>{t('ThirdRecharge.TotalCoins')}</span>
                <span>{formatMoney(linkInfo.totalCoins)}</span>
              </div>
            </div>
            <div className="pg-specify-recharge__bottom">
              <div className="pg-specify-recharge__btn" onClick={handleConfirm}>
                {t('Common.TopUp')}
              </div>
            </div>
          </div>
        )}
        {loading && <Loading />}
      </div>
    </HelmetProviders>
  )
}
