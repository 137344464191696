import React, { memo, useState, useCallback, useEffect, useRef } from 'react'
import './Methods.scss'
import clsx from 'clsx'
import Down from '../images/down.png'
import Right from '../images/right.png'
import ImageCoin from '../../../images/coin.png'
import { getPayListV2 } from '../../../services/thirdRecharge'
import { toast } from '../../../utils/bridge'
import { clearAuthInfo, isIOS } from '../../../utils/tool'
import Loading from '../../../components/Loading'
import Empty from './Empty'
import { useTrans } from '@/hooks/useTranslation'

type MethodsProps = {
  countryId: number
  selectProduct: string
  updateControlVal?: (data: object) => void
  queryUid?: string//请求充值的用户uid
  autoFillUserInfo?: () => void
}

const Methods = (props: MethodsProps) => {
  const [isExtend, setIsExtend] = useState<string>('')

  const refIsExtend = useRef(isExtend)
  refIsExtend.current = isExtend

  const [payList, setPayList] = useState<ThirdRecharge.PayItemV2[]>([])
  const { countryId, selectProduct, updateControlVal, queryUid } = props
  const [loading, setLoading] = useState<boolean>(false)

  const refCountryId = useRef(countryId)
  refCountryId.current = countryId

  const refQueryUid = useRef(queryUid)
  refQueryUid.current = queryUid

  // 充值列表
  const fetchPayListV2 = useCallback(async () => {
    const isInApp = !!window.bridge
    setLoading(true)
    const res = await getPayListV2({
      countryId: refCountryId.current,
      isSafari: isIOS,
      queryUid: refQueryUid.current,
      hasClientOpen: isInApp ? 1 : undefined
    })
    setLoading(false)

    if (res instanceof Error) {
      /* 临时判断登录过期 */
      // clearAuthInfo()
      // props.autoFillUserInfo && props.autoFillUserInfo()
      return
    }

    const { code, message, data } = res as Response<ThirdRecharge.PayItemV2[]>

    /* 登录过期 */
    if (code === 401) {
      // toast(message)
      clearAuthInfo()
      props.autoFillUserInfo && props.autoFillUserInfo()
      return
    }
    if (code !== 200) {
      toast(message)
      return
    }
    let newData = [] as any
    newData = data.map((item) => {
      const modifiedItem = {
        ...item,
        currencyTypeList: item.currencyTypeList.map((innerItem) => {
          const modifiedInnerItem = {
            ...innerItem,
            prodList: innerItem.prodList.map((innerMostItem) => ({
              ...innerMostItem,
              // 每项产品没有唯一id，也方便发起充值请求
              uid: `${item.payType}-${innerItem.currencyType}-${innerMostItem.channelId}-${innerMostItem.chargeProdId}`
            }))
          }
          return modifiedInnerItem
        })
      }
      return modifiedItem
    })
    // 埃及的特殊处理:otc和wallet等支付方式隐藏USD选择
    if (refCountryId.current === 5) {
      newData = newData.map((item: ThirdRecharge.PayItemV2) => {
        if (item.payType === 'OTC' || item.payType === 'WALLET' || item.payType === 'VIRTUAL_ACCOUNT' || item.payType === 'CARD' || item.payType === 'GOOGLEPAY') {
          const modifiedItem = {
            ...item,
            currencyTypeList: item.currencyTypeList.filter((innerItem) => innerItem.currencyTypeName !== 'USD')
          }
          return modifiedItem
        } else {
          return item
        }
      })
    }
    setPayList(newData)
  }, [props.autoFillUserInfo])

  useEffect(() => {
    if (props.countryId) {
      fetchPayListV2()
    }
    setIsExtend('')
  }, [props.countryId, props.queryUid, fetchPayListV2])

  const handleExtend = useCallback((val: string) => {
    if (refIsExtend.current === val) {
      setIsExtend('')
      return
    }
    setIsExtend(val)
  }, [])

  // 当支付渠道的可用商品没有时，隐藏该支付渠道,visa/master card 和 google pay除外
  const noInventoryHide = useCallback((payListItem: ThirdRecharge.PayItemV2) => {
    if (payListItem.payType === 'CARD' || payListItem.payType === 'GOOGLEPAY') return false
    let flag = false
    for (const item of payListItem.currencyTypeList) {
      // USD没有库存一说，一直是null
      if (item.currencyTypeName === 'USD') continue
      // 没有设置库存，不作处理
      if (item.surplusInventory === null) {
        break
      }
      // 库存用完了直接跳出
      if (item.surplusInventory <= 0) {
        flag = true
        break
      }
      flag = item.prodList.every(proItem => {
        if (item.surplusInventory !== null && Math.floor((item.surplusInventory / Number(proItem.money))) <= 0) {
          return true
        } else {
          return false
        }
      })
    }
    // 对埃及的limited-discount额外处理
    for (const item of payListItem.currencyTypeList) {
      // USD没有库存一说，一直是null
      if (item.currencyTypeName !== 'Limited Discount') continue
      // 没有设置库存，不作处理
      if (item.surplusInventory === null) {
        break
      }
      // 库存用完了直接跳出
      if (item.surplusInventory <= 0) {
        flag = true
        break
      }
      flag = item.prodList.every(proItem => {
        if (item.surplusInventory !== null && Math.floor((item.surplusInventory / Number(proItem.money))) <= 0) {
          return true
        } else {
          return false
        }
      })
    }
    return flag
  }, [])

  // 给ListItem组件传递需要隐藏的商品id合集
  const chargeProdIdHide = useCallback((payListItem: ThirdRecharge.PayItemV2) => {
    if (payListItem.payType === 'CARD' || payListItem.payType === 'GOOGLEPAY') return undefined
    const chargeProdIdHideArr: string[] = []
    payListItem.currencyTypeList.map(curTypeItem => {
      // 库存没有了直接隐藏了支付方式，所以不进入逻辑了
      if (curTypeItem.surplusInventory !== null) {
        const surplusInventory = curTypeItem.surplusInventory || 0
        curTypeItem.prodList.map(item => {
          if (Math.floor((surplusInventory / Number(item.money))) <= 0) {
            chargeProdIdHideArr.push(item.chargeProdId)
          }
        })
      }
    })
    return chargeProdIdHideArr
  }, [])
  return (
    <div className="methods">
      {loading && <Loading />}
      {payList.length !== 0 ? (
        <>
          {payList?.map((payListItem, i) => (
            <div key={i} className={clsx("methods-body", { hide: noInventoryHide(payListItem) })}>
              <div
                className="methods-body-item"
                onClick={() => handleExtend(payListItem.payTypeName)}>
                <div className="methods-body-item-content">
                  <div>
                    {payListItem.payPicUrls?.map((url: string, i) => (
                      <img
                        src={url}
                        key={i}
                        alt="img"
                        className="pay-type-icon"
                      />
                    ))}
                  </div>
                  <div className="pay-type-name">
                    <span>{payListItem?.payTypeName}</span>
                    <img
                      className={clsx('methods-body-item-icon', {
                        right: isExtend === payListItem.payTypeName
                      })}
                      alt="img"
                      src={isExtend === payListItem.payTypeName ? Down : Right}
                    />
                  </div>
                </div>
              </div>
              {isExtend === payListItem.payTypeName &&
                payListItem?.currencyTypeList?.map((curTypeItem, i) => (
                  <ListItem
                    key={i}
                    selectProduct={selectProduct}
                    currencyTypeList={curTypeItem}
                    updateControlVal={updateControlVal}
                    payType={payListItem.payType}
                    chargeProdIdHideArr={chargeProdIdHide(payListItem)}
                  />
                ))}
            </div>
          ))}
        </>
      ) : loading ? (
        <></>
      ) : (
        <Empty />
      )}
    </div>
  )
}

type ListItemProps = {
  selectProduct: string
  currencyTypeList: ThirdRecharge.currencyTypeListItem
  updateControlVal?: (data: object) => void
  payType: string
  chargeProdIdHideArr: string[] | undefined
}

const ListItem = (props: ListItemProps) => {
  const { t } = useTrans()
  const { currencyTypeList, selectProduct, updateControlVal, payType, chargeProdIdHideArr } = props
  const handleSelectCharge = (selectProduct: string) => {
    updateControlVal && updateControlVal({ selectProduct })
  }
  // 计算商品剩余数量：库存/本地币价格（向下取整）
  const inventory = useCallback((item: ThirdRecharge.prodListItem) => {
    const surplusInventory = currencyTypeList.surplusInventory || 0
    return Math.floor((surplusInventory / Number(item.money)))
  }, [props])
  // true没有库存隐藏商品
  const noInventoryHidePro = useCallback((item: ThirdRecharge.prodListItem) => {
    if (payType === 'CARD' || payType === 'GOOGLEPAY') return false
    if (chargeProdIdHideArr) {
      return chargeProdIdHideArr.includes(item.chargeProdId)
    } else if (currencyTypeList.surplusInventory && inventory(item) <= 0) {
      return true
    }
  }, [props, inventory])
  return (
    <>
      {!currencyTypeList.prodList.every(item => chargeProdIdHideArr?.includes(item.chargeProdId)) && <div className="methods-body-title">
        {currencyTypeList.currencyTypeName === 'Limited Discount' ? t('ThirdRecharge.LimitedDiscount') : currencyTypeList.currencyTypeName}
      </div>}
      <div className="methods-body-list">
        <div className="methods-body-list__coin">
          {currencyTypeList.prodList?.map((item) => (
            <div
              key={item.chargeProdId}
              className={clsx('methods-body-list__coin-item', {
                selected: item.uid === selectProduct,
                hide: noInventoryHidePro(item),
                'limited-discount': currencyTypeList?.currencyTypeName === 'Limited Discount'
              })}
              onClick={() => {
                handleSelectCharge(String(item.uid))
              }}>
              <div className="methods-body-list__coin-item-up">
                <img
                  alt="img"
                  className="methods-body-list__coin-icon"
                  src={ImageCoin}
                />
                <span>{item.goldNum}</span>
              </div>
              <div className="methods-body-list__coin-price">
                <span>
                  {currencyTypeList?.currencyTypeName === 'Limited Discount' ? 'EGP' : currencyTypeList?.currencyTypeName} {item?.money}
                </span>
              </div>
              {!!item.cashbackGoldNum && (
                <div className="methods-body-list__coin-item-return">
                  <span>+{item.cashbackGoldNum}</span>
                </div>
              )}
              {currencyTypeList.currencyTypeName !== 'USD' && payType !== 'CARD' && payType !== 'GOOGLEPAY' && currencyTypeList.surplusInventory !== null &&
                <div className='methods-body-list__coin-item-inventory'>
                  {t('ThirdRecharge.Inventory')}: {inventory(item)}
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(Methods)
