const config = {
  reqMode: 'net',
  net: {
    host: process.env.REACT_APP_API_URL
  },
  thirdRechargeUrl: location.href,
  encryptKey: 'd7ddcfc27878144829e116c405801af6'
}

export default config
