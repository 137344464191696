import axios from "axios";
import config from "../config";
import { getUserInfo } from "./bridge";

export const service = axios.create({
  baseURL: config.net.host,
  timeout: 30000
})

service.interceptors.request.use(
  async config => {
    const auth = await getUserInfo()
    config.params = {
      ...auth,
      ...config.params,
      ...['lang', 'deviceId'].reduce<{ [x: string]: string }>((ret, d) => {
        switch (d) {
          case 'lang':
            ret.language = localStorage.getItem(d) || ''
            break
          default:
            ret[d] = localStorage.getItem(d) || ''
        }
        return ret
      }, {})
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// service.interceptors.response.use(
//   res => {
//     const { status, data } = res
//     if (status !== 200) return null

//     return data
//   }
// )

