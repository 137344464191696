import React, { memo, useState, useCallback, useEffect, useRef } from 'react'
import './SpecifyRechargeMethods.scss'
import clsx from 'clsx'
import Down from '../images/down.png'
import Right from '../images/right.png'
import ImageCoin from '../../../images/coin.png'
import { getChargeProdPayListV2 } from '../../../services/thirdRecharge'
import { toast } from '../../../utils/bridge'
import { isIOS } from '../../../utils/tool'
import Loading from '../../../components/Loading'
import Empty from './Empty'

type MethodsProps = {
  selectProduct: string
  updateControlVal?: (data: object) => void
  appointPro: {
    money: string
    goldNum: number,
    cashbackGoldNum: number
    currencyTypeName: string
  }//指定pro
  appointCountryId?: number//指定国家id请求
}

const SpecifyRechargeMethods = (props: MethodsProps) => {
  const [isExtend, setIsExtend] = useState<string>('')

  const refIsExtend = useRef(isExtend)
  refIsExtend.current = isExtend

  const [payList, setPayList] = useState<ThirdRecharge.PayItemV2[]>([])
  const { appointCountryId, selectProduct, updateControlVal } = props
  const [loading, setLoading] = useState<boolean>(true)

  const refAppointCountryId = useRef(appointCountryId)
  refAppointCountryId.current = appointCountryId

  // 指定充值系列
  const refAppointPro = useRef(props.appointPro)
  refAppointPro.current = props.appointPro

  // 充值列表
  const fetchPayListV2 = useCallback(async () => {
    setLoading(true)
    const res = await getChargeProdPayListV2({
      countryId: refAppointCountryId.current,
      isSafari: isIOS
    })
    setLoading(false)
    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<ThirdRecharge.PayItemV2[]>
    if (code !== 200) {
      toast(message)
      return
    }
    let newData = [] as any
    // 封装格式
    newData = data.map((item) => {
      const modifiedItem = {
        ...item,
        currencyTypeList: item.currencyTypeList.map((innerItem) => {
          if (refAppointPro.current?.currencyTypeName === innerItem.currencyTypeName) {
            const modifiedInnerItem = {
              ...innerItem,
              prodList: innerItem.prodList.map((innerMostItem) => ({
                channelId: innerMostItem.channelId,
                chargeProdId: innerMostItem.chargeProdId,
                goldNum: refAppointPro.current?.goldNum,
                money: refAppointPro.current?.money,
                cashbackGoldNum: refAppointPro.current?.cashbackGoldNum,
                // 每项产品没有唯一id，也方便发起充值请求
                uid: `${item.payType}-${innerItem.currencyType}-${innerMostItem.channelId}-${innerMostItem.chargeProdId}`
              })).slice(0, 1)
            }
            return modifiedInnerItem
          }
        }).filter(Boolean)
      }
      return modifiedItem
    })
    // 埃及的特殊处理:只展示visa卡和google pay
    if (refAppointCountryId.current === 5 && refAppointPro.current.currencyTypeName === 'USD') {
      newData = newData.filter((item: ThirdRecharge.PayItemV2) => item.payType === 'CARD' || item.payType === "GOOGLEPAY")
    }
    setPayList(newData)
  }, [])

  useEffect(() => {
    if (appointCountryId) {
      fetchPayListV2()
    }

    setIsExtend('')
  }, [appointCountryId, fetchPayListV2])

  const handleExtend = useCallback((val: string) => {
    if (refIsExtend.current === val) {
      setIsExtend('')
      return
    }
    setIsExtend(val)
  }, [])

  return (
    <div className="specify-recharge-methods">
      {loading && <Loading />}
      {payList.length !== 0 ? (
        <>
          {payList?.map((payListItem, i) => (
            <div key={i} className={clsx("specify-recharge-methods-body", { hide: payListItem.currencyTypeList.length === 0 })}>
              <div
                className="specify-recharge-methods-body-item"
                onClick={() => handleExtend(payListItem.payTypeName)}>
                <div className="specify-recharge-methods-body-item-content">
                  <div>
                    {payListItem.payPicUrls?.map((url: string, i) => (
                      <img
                        src={url}
                        key={i}
                        alt="img"
                        className="pay-type-icon"
                      />
                    ))}
                  </div>
                  <div className="pay-type-name">
                    <span>{payListItem?.payTypeName}</span>
                    <img
                      className={clsx('specify-recharge-methods-body-item-icon', {
                        right: isExtend === payListItem.payTypeName
                      })}
                      alt="img"
                      src={isExtend === payListItem.payTypeName ? Down : Right}
                    />
                  </div>
                </div>
              </div>

              {isExtend === payListItem.payTypeName &&
                payListItem?.currencyTypeList?.map((curTypeItem, i) => (
                  <ListItem
                    key={i}
                    selectProduct={selectProduct}
                    currencyTypeList={curTypeItem}
                    updateControlVal={updateControlVal}
                  />
                ))}
            </div>
          ))}
        </>
      ) : loading ? (
        <></>
      ) : (
        <Empty />
      )}
    </div>
  )
}

type ListItemProps = {
  selectProduct: string
  currencyTypeList: ThirdRecharge.currencyTypeListItem
  updateControlVal?: (data: object) => void
}

const ListItem = (props: ListItemProps) => {
  const { currencyTypeList, selectProduct, updateControlVal } = props

  const handleSelectCharge = (selectProduct: string) => {
    updateControlVal && updateControlVal({ selectProduct })
  }
  return (
    <>
      <div className="specify-recharge-methods-body-title">
        {currencyTypeList.currencyTypeName}
      </div>
      <div className="specify-recharge-methods-body-list">
        <div className="specify-recharge-methods-body-list__coin">
          {currencyTypeList.prodList?.map((item) => (
            <div
              key={item.chargeProdId}
              className={clsx('specify-recharge-methods-body-list__coin-item', {
                selected: item.uid === selectProduct
              })}
              onClick={() => {
                handleSelectCharge(String(item.uid))
              }}>
              <div className="specify-recharge-methods-body-list__coin-item-up">
                <img
                  alt="img"
                  className="specify-recharge-methods-body-list__coin-icon"
                  src={ImageCoin}
                />
                <span>{item.goldNum}</span>
              </div>
              <div className="specify-recharge-methods-body-list__coin-price">
                <span>
                  {currencyTypeList?.currencyTypeName} {item?.money}
                </span>
              </div>
              {!!item.cashbackGoldNum &&
                <div className="specify-recharge-methods-body-list__coin-item-return">
                  <span>+{item.cashbackGoldNum}</span>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(SpecifyRechargeMethods)
