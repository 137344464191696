import clsx from 'clsx'
import { t } from 'i18next'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  getLocationCountry,
  getUserDetail,
  rechargeV2
} from '../../services/thirdRecharge'
import { isAndroid, isIOS, setAuthInfo, clearAuthInfo } from '../../utils/tool'
import { getUserInfo, toast } from '../../utils/bridge'
import { Popup } from 'antd-mobile'
import langIcon from '@/pages/ThirdRecharge/images/lang-icon.png'
import ImageCountry from '@/pages/ThirdRecharge/images/country.png'
import CountryList from './components/Country'
import Loading from '../../components/Loading'
import Methods from './components/Methods'
import Reseller from './components/Reseller'
import HelmetProviders from '../../components/HelmetProviders'
import config from '../../config';
import LogIn from './components/LogIn'
import AwardProgress from './components/AwardProgress';
import { useSearchParams } from 'react-router-dom'
import './index.scss'

type TabItem = {
  title: string
  value: number
}

const tabVal: TabItem[] = [
  { title: t('Common.Method'), value: 0 },
  { title: t('Common.Reseller'), value: 1 }
]

const langText: Record<string, string> = {
  en: 'English',
  ar: 'العربية'
}

const initControlVal = {
  appID: '',
  tabVal: 0,
  countryId: undefined,
  countryName: '',
  selectProduct: '',
  openCountry: false,
  isSafari: isIOS
}

export default function ThirdRecharge() {
  const [params] = useSearchParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [openLang, setOpenLang] = useState<boolean>(false)
  const [openLogIn, setOpenLogIn] = useState<boolean>(false)

  const refCountry = useRef<React.ElementRef<typeof CountryList>>(null)
  const refLoading = useRef(loading)
  refLoading.current = loading

  const refTimeout = useRef<NodeJS.Timeout>()

  const [controlVal, setControlVal] = useState<
    {
      appID: string
      tabVal: number
      selectProduct?: string
      countryName?: string
      openCountry: boolean
      user?: AgentRecharge.UserInfo
      isSafari: boolean
    } & ThirdRecharge.Country
  >(JSON.parse(JSON.stringify(initControlVal)))

  const refControlVal = useRef(controlVal)
  refControlVal.current = controlVal

  const updateControlVal = useCallback(async (data: object) => {
    setControlVal({ ...refControlVal?.current, ...data })
  }, [])

  // 获取用户信息
  const handleFetchUser = useCallback(async () => {

    setLoading(true)
    const res = await getUserDetail({ erbanNo: refControlVal.current.appID || undefined })
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<AgentRecharge.UserInfo>
    if (code !== 200) {
      toast(message)
      return
    }

    setControlVal(prev => ({ ...prev, user: data }))
  }, [])

  const handleClearUser = useCallback(() => {
    setControlVal({
      ...refControlVal?.current,
      user: undefined,
      selectProduct: ''
    })
  }, [])

  const hanndleAppIDChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setControlVal({ ...refControlVal?.current, appID: e.target.value })
    },
    []
  )

  // 获取定位国家
  const fetchLocationCountry = useCallback(async () => {
    const res = await getLocationCountry()
    setLoading(false)

    if (res instanceof Error) {
      return
    }
    const { code, message, data } = res as Response<ThirdRecharge.Country>
    if (code !== 200) {
      toast(message)
      return
    }
    setControlVal(prev => ({ ...prev, ...data }))
  }, [])

  //发起充值
  const handleConfirm = useCallback(async () => {
    if (refLoading.current) return
    //`${payType}-${currencyType}-${channelId}-${chargeProdId}`
    const paramVal = refControlVal.current.selectProduct
      ?.split('-')
      .filter((v) => v !== '-')
    const isInApp = !!window.bridge
    setLoading(true)
    const res = await rechargeV2({
      uid: String(refControlVal.current.user?.uid),
      payType: String(paramVal?.[0]),
      currencyType: Number(paramVal?.[1]),
      channelId: Number(paramVal?.[2]),
      chargeProdId: String(paramVal?.[3]),
      countryId: refControlVal.current.countryId,
      isSafari: refControlVal.current.isSafari,
      hasClientOpen: isInApp ? 1 : undefined
    })
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<{ redirectUrl: string }>
    /* 登录过期 */
    if (code === 401) {
      toast(t('Common.LoginExpired'))
      clearAuthInfo()
      autoFillUserInfo()
      return
    }
    if (code !== 200) {
      toast(message)
      return
    }
    toast(t('Common.Loading'))

    // setControlVal(JSON.parse(JSON.stringify(initControlVal)))
    window.location.href = data.redirectUrl
  }, [])

  const handleSelectLang = (lang: string) => {
    const curLang = localStorage.getItem('lang')
    if (curLang === lang) {
      setOpenLang(false)
      return
    }
    localStorage.setItem('lang', lang)
    setOpenLang(false)
    setTimeout(() => {
      const url = new URL(window.location.href)
      url.searchParams.set('lang', lang)
      // 将浏览器URL地址更新为修改后的URL,否则当url存在lang参数时会有问题
      window.history.replaceState(null, '', url)
      window.location.reload()
    }, 100)
  }

  const handleOpenCountry = () => {
    let newData: object = { openCountry: !controlVal.openCountry }

    // 选中的国家与原定位国家不同
    if (
      refCountry.current &&
      refCountry?.current?.countryId !== refControlVal.current.countryId
    ) {
      newData = {
        ...newData,
        countryName: String(refCountry?.current?.countryName),
        countryId: Number(refCountry?.current?.countryId),
        selectProduct: ''
      }
    }
    setControlVal({ ...controlVal, ...newData })
  }

  /* 拉起app授权登录 */
  const openAppLogin = useCallback(() => {
    const isInApp = !!window.bridge
    if (isInApp) {
      //在app内不用进行授权登录，自动填写用户信息
      autoFillUserInfo()
      return
    }
    const scheme = process.env.REACT_APP_NAME + "://webloginauth?" + config.thirdRechargeUrl// URL Scheme
    if (isAndroid) {
      //大部分浏览器使用URL Scheme就可以唤起，同时安装了两个包的手机的谷歌浏览器才需要intent语法
      window.location.href = scheme;
    } else if (isIOS) {
      //iOS终端直接使用location
      window.location.href = scheme;
    } else {
      window.location.href = scheme;
    }
    // 一般2.5秒判定是否唤起成功，超过了认定为唤起app失败
    refTimeout.current = setTimeout(function () {
      if (isAndroid) {
        //安卓端最后一次尝试唤起app
        //安卓的原生谷歌浏览器从chrome25版本之后就不能通过URL Schemes唤醒安卓应用。要使用谷歌官方提供的intent:
        const link = document.createElement("a")
        link.style.display = "none"
        link.href = `intent://webloginauth?${config.thirdRechargeUrl}#Intent;scheme=${process.env.REACT_APP_NAME};package=${process.env.REACT_APP_PACKAGE_NAME};action=android.intent.action.VIEW;end`
        link.click()
      }
      setOpenLogIn(true)
    }, 2500);
  }, [])
  useEffect(() => {
    // 如果页面隐藏，推测唤起app成功，清除定时器
    function onVisibilityChange() {
      if (document.hidden) {
        clearTimeout(refTimeout.current)
      }
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
  /* 自动填充用户信息 */
  const autoFillUserInfo = useCallback(() => {
    getUserInfo().then((auth) => {
      if (auth) {
        handleFetchUser()
      } else {
        openAppLogin()
      }
    })
  }, [])
  /* 登录 */
  useEffect(() => {
    const uid = window.decodeURIComponent(window.atob(params.get('u') || ''))
    const ticket = window.decodeURIComponent(window.atob(params.get('t') || ''))
    if (uid && ticket) {
      setAuthInfo({ uid, ticket })
      autoFillUserInfo()
    }
  }, [params])

  useEffect(() => {
    fetchLocationCountry()
    getUserInfo().then((auth) => {
      if (auth) {
        handleFetchUser()
      }
    })
  }, [])

  return (
    <HelmetProviders title={t('Common.TopUp')}>
      <div className="pg-recharge">
        <div className="sticky-header">
          <div className="cmp-pane first">
            <div className="cmp-pane__title">
              {t('Guild.Tips.PleaseCheckYourAccountInformation')}
            </div>

            <div
              className={clsx('pg-recharge-lang', {
                display: openLang
              })}>
              <div
                className="pg-recharge-lang-current"
                onClick={() => setOpenLang(!openLang)}>
                <span className="pg-recharge-lang-current-text">
                  {t('Common.lang')}
                </span>
                <img
                  className="pg-recharge-lang-current-icon"
                  alt="img"
                  src={langIcon}
                />
              </div>
              <div
                onClick={() => handleSelectLang('en')}
                className="pg-recharge-lang-options">
                {langText['en']}
              </div>
              <div
                onClick={() => handleSelectLang('ar')}
                className="pg-recharge-lang-options">
                {langText['ar']}
              </div>
            </div>
          </div>
          <div className="pg-recharge__input">
            {!controlVal.user && (
              <input
                type="text"
                onChange={hanndleAppIDChange}
                placeholder={t('Guild.Tips.EnterAppID', {APP: process.env.REACT_APP_DISPLAY_NAME}).toString()}
                value={controlVal.appID}
              />
            )}
            {controlVal.user && (
              <div className="pg-recharge__user">
                <img
                  className="pg-recharge__user-avatar"
                  alt="img"
                  src={controlVal.user.avatar}
                />
                <span className="pg-recharge__user-name">
                  {controlVal.user.nick}
                </span>
              </div>
            )}
            {!controlVal.user && (
              <div className="pg-recharge__confirm" onClick={handleFetchUser}>
                {t('Common.Confirm')}
              </div>
            )}
            {controlVal.user && (
              <div className="pg-recharge__cancel" onClick={handleClearUser}>
                {t('Common.Cancel')}
              </div>
            )}
          </div>
          {/* 进度条 */}
          {controlVal.user &&
            <AwardProgress current={controlVal.user.monthMoney || 0} />
          }
          {/* Tabs */}
          <div className="pg-recharge-tabs">
            {tabVal.map((item) => (
              <div
                key={item.value}
                className={clsx('pg-recharge-tabs-tab', {
                  'pg-recharge-tabs-noselect': controlVal.tabVal !== item.value
                })}
                onClick={() => {
                  updateControlVal({ tabVal: item.value })
                }}>
                <span>{item.title}</span>
                {controlVal.tabVal === item.value && (
                  <div className="pg-recharge-tabs-line" />
                )}
              </div>
            ))}
          </div>
        </div>
        {controlVal.tabVal === 0 && (
          <div className="max-height-style">
            {controlVal.countryId && (
              <>
                <div className="pg-recharge-country">
                  <img
                    className="pg-recharge-country-icon"
                    alt="img"
                    src={ImageCountry}
                    onClick={handleOpenCountry}
                  />
                  <span onClick={handleOpenCountry}>
                    {controlVal?.countryName || ''}
                  </span>
                </div>
              </>
            )}

            <Methods
              countryId={controlVal.countryId}
              selectProduct={String(controlVal.selectProduct)}
              updateControlVal={updateControlVal}
              queryUid={controlVal.user?.uid}
              autoFillUserInfo={autoFillUserInfo}
            />
          </div>
        )}
        {controlVal.tabVal === 1 && (
          <Reseller countryId={controlVal.countryId} />
        )}
        {/* 选择国家 */}
        <Popup
          visible={controlVal.openCountry}
          className="country-popup"
          onMaskClick={handleOpenCountry}>
          <CountryList
            ref={refCountry}
            {...{
              updateControlVal,
              handleOpenCountry,
              countryId: controlVal.countryId
            }}
          />
        </Popup>

        {controlVal.selectProduct && controlVal.user && (
          <div className="pg-recharge__bottom">
            <div className="pg-recharge__btn" onClick={handleConfirm}>
              {t('Common.TopUp')}
            </div>
          </div>
        )}
        {loading && <Loading />}
        <LogIn openLogIn={openLogIn} setOpenLogIn={setOpenLogIn} />
      </div>
    </HelmetProviders>
  )
}
