import React from 'react'
import { GuildStore } from './guild'

type StoreProps = {
  children: any
}

export default function Store({ children }: StoreProps) {
  return <GuildStore>{children}</GuildStore>
}
