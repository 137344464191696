import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd-mobile';
import arLocale from './locales/antd-mobile/ar'
import enLocale from 'antd-mobile/cjs/locales/en-US'
import Store from './store'
import ThirdRecharge from './pages/ThirdRecharge';
import SpecifyRecharge from './pages/ThirdRecharge/SpecifyRecharge';
import './components/i18n'
import './index.css'
import Tips from './pages/Tips';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const locale = window.lang === 'ar' ? arLocale : enLocale
root.render(
  <ConfigProvider locale={locale}>
    <Store>
      <BrowserRouter>
        <Routes>
          <Route path='/recharge'>
            <Route index element={<ThirdRecharge />} />
          </Route>
          <Route path='/specify-recharge'>
            <Route index element={<SpecifyRecharge />} />
          </Route>
          <Route path='/tips'>
            <Route index element={<Tips />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Store>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
