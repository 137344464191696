import React, { memo, useCallback, useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import './Methods.scss'
import { t } from 'i18next'
import { getAgencyt } from '../../../services/thirdRecharge'
import './Reseller.scss'
import { toast } from '../../../utils/bridge'
import Loading from '../../../components/Loading'
import ImageCopy from '../images/copy.png'
import ImageFire from '../images/fire.png'
import Empty from './Empty'

type ResellerProps = {
  countryId: number
}
const Reseller = (pros: ResellerProps) => {
  const { countryId } = pros
  const refCountryId = useRef(countryId)
  refCountryId.current = countryId
  const [loading, setLoading] = useState<boolean>(false)
  const [agencyList, setAgencyList] = useState<ThirdRecharge.Agency[]>([])

  const handleCopy = useCallback((data: string) => {
    const ele = document.createElement('input')
    ele.setAttribute('type', 'textarea')
    document.body.appendChild(ele)
    ele.value = data
    ele.select()
    ele.setSelectionRange(0, ele.value.length)
    document.execCommand('copy')
    document.body.removeChild(ele)

    Toast.show({
      content: t('Copy success!')
    })
  }, [])

  const fetchAgencyList = useCallback(async () => {
    setLoading(true)
    const res = await getAgencyt({ countryId: refCountryId.current })
    setLoading(false)
    if (res instanceof Error) {
      return
    }
    const { code, message, data } = res as Response<ThirdRecharge.Agency[]>
    if (code !== 200) {
      toast(message)
      return
    }
    setAgencyList(data ? [...data] : [])
  }, [])

  useEffect(() => {
    fetchAgencyList()
  }, [fetchAgencyList])

  return (
    <div className="reseller">
      {loading && <Loading />}
      <div className="reseller-tip">
        <div className="reseller-tip-top">
          {t('ThirdRecharge.OfficialResellers')}
        </div>
        <div className="reseller-tip-top">
          {t('ThirdRecharge.AvailableRecharg', {APP: process.env.REACT_APP_DISPLAY_NAME})}
        </div>
      </div>
      <div className="reseller-line" />
      {agencyList.length !== 0 ? (
        <>
          {agencyList.map((item) => (
            <div key={item.erbanNo} className="reseller-list">
              <div className="reseller-list-left">
                <div className="reseller-list-left-avatar">
                  <img alt="img" src={item.avatar} />
                </div>
                <div className="reseller-list-left-info">
                  <div>{item.nick}</div>
                  <div className="reseller-list-left-info-copy">
                    <span onClick={() => handleCopy(String(item.erbanNo))}>
                      ID:{item.erbanNo}
                    </span>
                    <img
                      alt="img"
                      src={ImageCopy}
                      onClick={() => handleCopy(String(item.erbanNo))}
                    />
                  </div>
                </div>
              </div>

              <div className="reseller-list-right">
                <img alt="img" src={ImageFire} />
                <div className="reseller-list-right-count">
                  {item?.heat || 0}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : loading ? (
        <></>
      ) : (
        <Empty />
      )}
    </div>
  )
}

export default memo(Reseller)
