import React, { memo } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import SafeArea from './SafeArea'

export type HelmetProvidersProps = {
  title?: string | null
  children: any
  width?: string
  initialScale?: string
  minimumScale?: string
  maximumScale?: string
}
const HelmetProviders = (props: HelmetProvidersProps) => {
  const {
    title = 'Privacy',
    children,
    width = 'device-width', // 设置width的默认值为'device-width'
    initialScale = '1.0', // 设置initialScale的默认值为'1.0'
    minimumScale = '1.0', // 设置minimumScale的默认值为'1.0'
    maximumScale = '1.0' // 设置maximumScale的默认值为'1.0'
  } = props
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name="viewport"
          content={`width=${width}, initial-scale=${initialScale}, minimum-scale=${minimumScale}, maximum-scale=${maximumScale}`}></meta>
      </Helmet>
      <SafeArea position="top" />
      {children}
      <SafeArea position="bottom" />
    </HelmetProvider>
  )
}

export default memo(HelmetProviders)
