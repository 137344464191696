import React, { useCallback, useState, ChangeEvent, useMemo, useRef } from 'react'
import './LogIn.scss'
import ImgLogo from '@/images/logo.webp';
import clsx from 'clsx';
import { DESAndBase64Encrypt, setAuthInfo } from '@/utils/tool';
import Loading from '@/components/Loading';
import { toast } from '@/utils/bridge';
import { idLogin } from '@/services/thirdRecharge';

interface PropsType {
    openLogIn: boolean
    setOpenLogIn: (openLogIn: boolean) => void
}
function LogIn({ openLogIn, setOpenLogIn }: PropsType) {
    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [inputState, setInputState] = useState({
        inputAppId: '',
        inputPassword: ''
    })
    const refInputState = useRef(inputState)
    refInputState.current = inputState

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>, name: string) => {
            switch (name) {
                case 'appID':
                    setInputState(prev => ({
                        ...prev,
                        inputAppId: e.target.value
                    }))
                    break
                case 'password':
                    setInputState(prev => ({
                        ...prev,
                        inputPassword: e.target.value
                    }))
                    break
            }
        },
        []
    )
    const handleLogIn = useCallback(async () => {
        if (!refInputState.current.inputAppId || !refInputState.current.inputPassword || refLoading.current) return
        const psw = DESAndBase64Encrypt(refInputState.current.inputPassword)
        setLoading(true)
        const { code, message, data } = await idLogin({
            erBanNo: refInputState.current.inputAppId,
            password: psw
        })
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setAuthInfo(data)
        window.location.href = location.href.split('?')[0]
    }, [setOpenLogIn])
    const isCanLogIn = useMemo(() => {
        let flag = true
        if (inputState.inputAppId.length < 1 || inputState.inputPassword.length < 6) {
            flag = false
        }
        return flag
    }, [inputState])
    return (
        <div className={clsx('cmp-log-in', { hide: !openLogIn })} onClick={(e) => e.stopPropagation()}>
            <div className='cmp-log-in__dialog' >
                <img src={ImgLogo} alt="" className='cmp-log-in__logo' />
                <input type="text" className='cmp-log-in__app' placeholder={`Please enter your ${process.env.REACT_APP_DISPLAY_NAME} ID`} onChange={(e) => handleChange(e, 'appID')} value={inputState.inputAppId} />
                <input type="text" className='cmp-log-in__password' placeholder='Password' onChange={(e) => handleChange(e, 'password')} value={inputState.inputPassword} />
                <button className={clsx('cmp-log-in__btn', { gray: !isCanLogIn })} disabled={!isCanLogIn} onClick={handleLogIn}></button>
            </div>
            {loading && <Loading />}
        </div>
    )
}

export default LogIn