import { service } from '../utils/net'

export function getUserDetail(params: ThirdRecharge.SearchUserParam) {
  return service<Response<AgentRecharge.UserInfo>>({
    url: '/user/search/user',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function recharge(params: ThirdRecharge.RechargeParam) {
  return service<Response<{ redirectUrl: string }>>({
    method: 'POST',
    url: '/official/website/place',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getPayList() {
  return service<Response<ThirdRecharge.PayItem[]>>({
    method: 'POST',
    url: '/chargeProd/list/h5'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getPayListV2(params: object) {
  return service<Response<ThirdRecharge.PayItemV2[]>>({
    method: 'GET',
    params,
    url: '/chargeProd/list/h5/v2'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getChargeProdPayListV2(params: object) {
  return service<Response<ThirdRecharge.PayItemV2[]>>({
    method: 'GET',
    params,
    url: '/chargeProd/list/h5/link'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getLocationCountry() {
  return service<Response<ThirdRecharge.Country>>({
    method: 'GET',
    url: 'chargeProd/get/location/country'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getCountryList() {
  return service<Response<ThirdRecharge.Country[]>>({
    method: 'GET',
    url: 'chargeProd/get/country/list'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function rechargeV2(params: ThirdRecharge.RechargeParamV2) {
  return service<Response<{ redirectUrl: string }>>({
    method: 'POST',
    url: '/official/website/place/v2',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function getAgencyt(params: object) {
  return service<Response<ThirdRecharge.Agency[]>>({
    method: 'GET',
    params,
    url: 'agency/hot/list'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 链接充值列表
export function getLinkInfo(chargeRecordId: string) {
  return service<Response<ThirdRecharge.LinkInfo>>({
    method: 'GET',
    url: 'chargeProd/link/list/h5',
    params: {
      chargeRecordId
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 发起充值(链接支付)
export function linkPay(params: { chargeLinkRecordId: string, payType: string, isSafari: boolean }) {
  return service<Response<{ redirectUrl: string }>>({
    method: 'POST',
    url: 'official/website/place/link',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 登录
export function idLogin(params: ThirdRecharge.IdLoginParams): Promise<Response<ThirdRecharge.IdLoginInfo>> {
  return service<Response<ThirdRecharge.IdLoginInfo>>({
    method: 'POST',
    url: 'auth/id/login/h5',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}