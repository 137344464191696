import React, {
  memo,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react'
import './Methods.scss'
import { t } from 'i18next'
import { toast } from '../../../utils/bridge'
import { getCountryList } from '../../../services/thirdRecharge'
import SelectedIcon from '../images/selected.png'
import Loading from '../../../components/Loading'
import UnselectIcon from '../images/unselect.png'
import './Country.scss'

type CountryDataProps = {
  countryId: number
  countryName: string
}
type CountryProps = {
  countryId: number
  handleOpenCountry?: () => void
}

const Country = (props: CountryProps, ref: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [country, setCountry] = useState<ThirdRecharge.Country>()
  const refCountry = useRef(country)
  refCountry.current = country

  const [countryList, setCountryList] = useState<ThirdRecharge.Country[]>([])

  useEffect(() => {
    if (refCountry.current?.countryId !== props.countryId) {
      fetchCountryList()
    }
    setCountry({ countryName: '', countryId: props.countryId })
  }, [props.countryId])

  // 获取国家列表
  const fetchCountryList = async () => {
    setLoading(true)
    const res = await getCountryList()
    setLoading(false)
    if (res instanceof Error) {
      return
    }
    const { code, message, data } = res as Response<ThirdRecharge.Country[]>
    if (code !== 200) {
      toast(message)
      return
    }
    setCountryList([...data])
  }

  const handleSelectCountry = (country: ThirdRecharge.Country) => {
    setCountry(country)

    setTimeout(() => {
      props?.handleOpenCountry?.()
    }, 0)
  }

  useImperativeHandle(ref, () => country)

  return (
    <div className="country">
      {loading && <Loading />}
      <div className="country-title">{t('Common.country')}</div>
      <div className="country-option">{t('Common.option')}</div>
      <div className="country-list">
        {countryList?.map((item) => (
          <div
            key={item.countryId}
            className="country-item"
            onClick={() => handleSelectCountry(item)}>
            <div className="country-item-left">
              <img
                className="country-item-select"
                alt="img"
                src={item.countryNationalFlag}
              />
              <span>{item.countryName}</span>
            </div>
            <img
              className="country-item-select"
              alt="img"
              src={
                country?.countryId === item.countryId
                  ? SelectedIcon
                  : UnselectIcon
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(forwardRef<CountryDataProps, CountryProps>(Country))
