import React from 'react'
import { useTrans } from '@/hooks/useTranslation'
import ImageEmpty from '../images/empty.png'
import './Empty.scss'

export default function Empty() {
  const { t } = useTrans()

  return (
    <div className="pg-recharge-empty">
      <img className="pg-recharge-empty__icon" alt="img" src={ImageEmpty} />
      <span>{t('Common.NoData')}</span>
    </div>
  )
}
