import i18n from "../components/i18n"

export function val2str(val: number): string {
  const str = val.toString()
  const ind = str.indexOf('.')
  return ind > -1 ? str.slice(0, ind + 2) : str
}

export function time2str(val: number): string {
  let str = val2str(val)
  const ind = str.indexOf('.')
  if (ind === -1) return str

  str = str.replace(/\.(\d)/, ($, $1) => {
    const num = Number($1)
    return num >= 5 ? `.5` : '.0'
  })
  return str
}

export function formatCharmValue(val: number): string {
  return val > 1000000
    ? val2str(val / 1000000) + 'M'
    : val > 1000
      ? val2str(val / 1000) + 'K'
      : val.toString()
}

export function formatGuildTime(val: number): string {
  return val > 30
    ? time2str(val / 60) + ' ' + i18n.t('Common.Hours')
    : time2str(val) + ' ' + i18n.t('Common.Minutes')
}

// 数字转金额格式
export function formatMoney(number: string | number, thousandsSeparator = ',') {
  if (isNaN(parseInt(number.toString()))) {
    return '';
  }
  // 取数字部分
  number = parseInt(number.toString())

  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  return parts[0];
}