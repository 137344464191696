import React from 'react'
import { createContext, Dispatch, useReducer } from 'react'

const initialState = {
  guildId: '',
  guildNo: '',
  guildName: '',
  guildImage: '',
  guildSlogan: ''
}

interface IGuildContextProps {
  state: typeof initialState
  dispatch: Dispatch<any> | null
}

const reducer = (state: typeof initialState, action: ReduceAction) => {
  switch (action.type) {
    default:
      return state
    case 'update':
      return {
        ...state,
        ...action.data
      }
  }
}

export const GuildStore = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GuildContext.Provider value={{ state, dispatch }}>
      {children}
    </GuildContext.Provider>
  )
}

export const GuildContext = createContext({
  state: initialState,
  dispatch: null
} as IGuildContextProps)
