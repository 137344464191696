import { Modal, Toast } from "antd-mobile"
import { getAuthInfo } from "./tool"

function callHandler(name: string, params: any = {}, callback: any = () => { }) {
  window.bridge.callHandler(name, params, callback)
}

function registerHandler(name: string, handler: any = () => { }) {
  window.bridge.registerHandler(name, handler)
}

export function waitBridge(time = 10) {
  let t = time
  let handler: NodeJS.Timer
  function check(resolve: (n: boolean) => void) {
    if (!t) {
      handler && clearInterval(handler)
      return resolve(false)
    }
    if (window.bridge) {
      handler && clearInterval(handler)
      return resolve(true)
    }
  }
  return new Promise((resolve) => {
    check(resolve)
    handler = setInterval(() => {
      t = t - 1
      check(resolve)
    }, 200)
  })
}

export async function getUserInfo(): Promise<UserBasicInfo | undefined> {
  let auth = getAuthInfo()
  if (!window.bridge && (window.isAndroid || window.isIOS) && !auth) {
    await waitBridge()
  }
  const isInApp = !!window.bridge
  try {
    if (isInApp) {
      auth = await new Promise((resolve) =>
        callHandler('gainTicketHandler', {}, resolve)
      )
    } else {
      const authStr = localStorage.getItem('auth') || ''
      auth = JSON.parse(authStr)
    }
  } catch (e) {
    return
  }

  return auth
}

export function goBack() {
  const isInApp = !!window.bridge
  if (isInApp) {
    callHandler('backToFront')
    return
  }

  window.history.back()
}

export function toast(msg: string) {
  const isInApp = !!window.bridge
  if (isInApp) {
    callHandler('showToast', { msg })
    return
  }

  Toast.show({ content: msg })
}

export function alert(msg: string) {
  const isInApp = !!window.bridge
  if (isInApp) {
    return new Promise(resolve => {
      const handler = setTimeout(() => resolve(false), 30 * 60 * 1000)
      registerHandler('confirmAck', (confirm: boolean) => {
        clearTimeout(handler)
        resolve(confirm)
      })
      callHandler('showAlert', { msg })
    })
  }

  return Modal.confirm({
    content: msg
  })
}

export function uploadAvatar(): Promise<string> {
  const isInApp = !!window.bridge
  if (isInApp) {
    return new Promise(resolve => {
      registerHandler('avatarUploadAck', ({ url }: { url: string }) => resolve(url))
      callHandler('showAvatarPopView')
    })
  }

  return Promise.resolve('')
}

export function setBusinessStatus(type: number) {
  const isInApp = !!window.bridge
  if (isInApp) {
    callHandler('setBusinessInfo', { type })
  }
}

export function openPage(page: number) {
  const isInApp = !!window.bridge
  if (isInApp) {
    callHandler('goto', { page })
  }
}

export function exitWeb() {
  const isInApp = !!window.bridge
  if (isInApp) {
    callHandler('exit')
  }
}